<template>
  <div class="dashboard">
    <b-container class="shlajsna">
  <b-row>
    <b-col cols="12">
      <h1 class="text-center">{{ $t('pages.all-pages') }}</h1>
    </b-col>
    <b-col cols="12">
      <NavBar></NavBar>
    </b-col>
    <b-col cols="12" class="px-4">
      <b-overlay :show="showOverlay">
        <template v-if="pages.length > 0">
        <b-list-group>
          <b-list-group-item v-for="p in pages" :key="p.id"
            :class="'d-flex align-items-center enabled-' + p.published"
            :to="{ name : 'EditPage', params: { pageId: p.id }}">
            <b-avatar variant="primary" :src="p.language.icon" class="mr-4"></b-avatar>
            <h3 class="display-5 mr-4">{{ p.title }}</h3>
            <b-badge variant="light" class="mr-3">{{ 'https://dmn.rtv.rs/' + p.language.shortName + '/' + p.url }}</b-badge>
            <b-badge variant="info">{{ dateTime(p.edited, "medium") }}</b-badge>
          </b-list-group-item>
        </b-list-group>
      </template>
      <template v-else>
        <h3>{{ $t('pages.no-pages')}}</h3>
      </template>
      </b-overlay>
    </b-col>
  </b-row>
</b-container>
</div>
</template>

<script>
import NavBar from "@/components/common/NavBar.vue"
import { BAvatar, BBadge } from 'bootstrap-vue'

export default {
  name: 'AllPagesForLanguage',
  components: { NavBar, BAvatar, BBadge},
  data: function() {
    return {
      pages: [],
      showOverlay: true
    }
  },
  created: async function() {
    await this.$http.get("/cms/api/page/all/" + this.$route.params.languageShortName, { headers: {Authorization: this.getAuthData().accessToken }})
      .then(r => {
        this.pages = r.data;
      }).catch(e => { this.handleError(e); })
    this.showOverlay = false;
  }
}
</script>
<style></style>
